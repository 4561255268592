import React from "react";
import Logo from "../components/Logo/Logo";
import Tile from "../components/Tile/Tile";
import { IItem } from "../constants/coffees";
import Modal from "react-modal";
import ProductModal from "../components/ProductModal/ProductModal";
import { IOrder } from "../App";
import OrderConfirmation from "../components/OrderConfirmModal/OrderConfirmation";
import shallowEqual from "../helpers/shallowEqual";

const MakeOrderPage = () => {
  const [isProductModalOpen, setProductModalOpen] = React.useState(false);
  const [coffeeIdx, setCoffeeIdx] = React.useState(0);
  const [items, setItems] = React.useState<IItem[]>([]);
  const [totalAmount, setTotalAmount] = React.useState(0);

  const [order, setOrder] = React.useState<IOrder>({
    items: [],
  });

  React.useEffect(() => {
    const fetchProducts = async () => {
      const url = `${process.env.REACT_APP_BACKEND_URL}/items`;
      const res = await fetch(url);
      const data = await res.json();

      setItems(
        data.map((item: IItem) => {
          return { ...item, count: 1 };
        })
      );
    };

    fetchProducts();
  }, []);

  const addToOrder = (itemToAdd: IItem) => {
    const itIdx = order.items.findIndex(
      (item) =>
        item.name === itemToAdd.name &&
        shallowEqual(item.options || {}, itemToAdd.options || {})
    );

    if (itIdx !== -1) {
      let newItems = order.items;
      newItems.splice(itIdx, 1, {
        ...itemToAdd,
        count: order.items[itIdx].count! + itemToAdd.count!,
      });
      setOrder((prevOrder) => ({
        ...prevOrder,
        items: newItems,
      }));
      setTotalAmount(
        (prevAmount) => prevAmount + itemToAdd.count! * itemToAdd.price
      );
    } else {
      setOrder((prevOrder) => ({
        ...prevOrder,
        items: prevOrder.items.concat({ ...itemToAdd, id: itemToAdd.id }),
      }));
      setTotalAmount(
        (prevAmount) => prevAmount + itemToAdd.count! * itemToAdd.price
      );
    }
  };

  const removeItemFromOrder = (itemToRemove: IItem) => {
    const itIdx = order.items.findIndex(
      (item) =>
        item.name === itemToRemove.name &&
        shallowEqual(item.options || {}, itemToRemove.options || {})
    );

    setOrder((prevOrder) => ({
      ...prevOrder,
      items: prevOrder.items.filter((_, idx) => idx !== itIdx),
    }));
    setTotalAmount(
      (prevAmount) => prevAmount - itemToRemove.count! * itemToRemove.price
    );
  };

  const sortItems = () => {
    const sortedItems = order.items.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    return sortedItems;
  };

  const submitOrder = async () => {
    try {
      const sortedItems = sortItems();
      const url = process.env.REACT_APP_BACKEND_URL + "/orders";
      await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application.json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...order, items: sortedItems }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setOrder({ items: [] });
          setTotalAmount(0);
          console.log("Order created!");
        });
    } catch (err) {
      console.log(err);
    }
  };

  Modal.setAppElement("#root");

  const openProductModal = () => {
    setProductModalOpen(true);
  };

  const closeProductModal = () => {
    setProductModalOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={isProductModalOpen}
        onRequestClose={closeProductModal}
        contentLabel="Product Modal"
        style={{
          overlay: {
            position: "fixed",
            zIndex: 1020,
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            background: "rgba(55, 55, 55, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          content: {
            background: "white",
            width: "35rem",
            maxWidth: "calc(100vw - 2rem)",
            maxHeight: "calc(100vh - 2rem)",
            overflowY: "auto",
            position: "relative",
            border: "1px solid #ccc",
            borderRadius: "0.3rem",
          },
        }}
      >
        <ProductModal
          closeModal={closeProductModal}
          addToOrder={addToOrder}
          {...items[coffeeIdx]}
        />
      </Modal>

      <div className="h-screen max-h-screen bg-gray-800">
        <div className="pb-10">
          <Logo name="UBaRe" />
        </div>
        <div className="max-h-[80%] flex justify-around">
          <div className="w-[55%] ml-3 pt-5 pb-5 pr-0.5 flex flex-wrap gap-6 justify-center overflow-y-auto scrollbar">
            {items.map((item, idx) => (
              <Tile
                key={item.name}
                onClick={() => {
                  // Parse item, to not count possible options
                  addToOrder({ ...item, options: [] });
                }}
                onExtendedModalOpen={() => {
                  setCoffeeIdx(idx);
                  openProductModal();
                }}
                {...item}
              />
            ))}
          </div>
          <div className="flex-col mx-4 max-h-[80%] w-[45%] bg-lightyellow rounded-xl">
            <OrderConfirmation
              removeItemFromOrder={removeItemFromOrder}
              order={order}
              totalAmount={totalAmount}
            />
            <div className="flex justify-center w-100 -mt-14 pt-0">
              <button
                onClick={submitOrder}
                className=" items-center bg-lightblue text-lightyellow rounded-md py-2 px-5 mb-3 text-md font-bold active:scale-105 active:drop-shadow-xl"
              >
                Odoslať objednávku
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MakeOrderPage;
