const shallowEqual = (object1: any, object2: any) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key in object1) {
    if (!(key in object2) || object1[key] !== object2[key]) {
      return false;
    }
  }
  for (let key in object2) {
    if (!(key in object1)) {
      return false;
    }
  }
  return true;
};

export default shallowEqual;
