import React from "react";
import Logo from "../components/Logo/Logo";
import { IOrder } from "../App";
import ActiveOrderTile from "../components/Tile/ActiveOrderTile";
import { echo } from "../service/socket";
import useSound from "use-sound";
import cinkSfx from "../sounds/cink.mp3";

const ActiveOrdersPage = () => {
  const [orders, setOrders] = React.useState<IOrder[]>([]);
  const [playSound] = useSound(cinkSfx);
  const soundRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    echo.channel("order-created").listen("OrderEvent", (order: any) => {
      console.debug(`Order received: ${order.id}`);
      soundRef.current?.click();
      setOrders((prevState) => [order, ...prevState]);
    });
    echo.channel("order-deleted").listen("OrderEvent", (order: any) => {
      console.debug(`Order deleted: ${order.id}`);
      setOrders((prevState) =>
        prevState.filter((prevOrder) => prevOrder.id !== order.id)
      );
    });

    // initial fetch
    fetchOrders();
  }, []);

  const fetchOrders = React.useCallback(async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/orders`;
    const res = await fetch(url);
    const data = await res.json();

    setOrders(data);
  }, []);

  const deleteOrder = React.useCallback(async (_id: number) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/orders/${_id}`;
      await fetch(url, {
        method: "DELETE",
        headers: {
          Accept: "application.json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ _id }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <div className="h-full pb-10 bg-gray-800">
      <input onClick={() => playSound()} ref={soundRef} className="hidden" />
      <div className="pb-10">
        <Logo name="UBaRe" />
      </div>
      <div className="flex flex-col gap-y-6 items-center mx-10">
        {orders.map((order, idx) => (
          <ActiveOrderTile
            key={idx}
            id={idx}
            {...order}
            onDelete={deleteOrder}
          />
        ))}
      </div>
    </div>
  );
};

export default ActiveOrdersPage;
