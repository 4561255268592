import Echo from "laravel-echo";

export const pusher = require("pusher-js");

export const echo = new Echo({
  broadcaster: "pusher",
  key: process.env.REACT_APP_WEBSOCKET_KEY,
  cluster: process.env.REACT_APP_WEBSOCKET_CLUSTER,
  encrypted: true,
  wsHost: process.env.REACT_APP_WEBSOCKET_HOST,
  wsPort: process.env.REACT_APP_WEBSOCKET_PORT,
  wssPort: process.env.REACT_APP_WEBSOCKET_SECURE_PORT,
  forceTLS: false,
  disableStats: true,
});
