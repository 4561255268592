import { IOrder } from "../../App";
import optionsString from "../../helpers/optionsString";
import parseTime from "../../helpers/parseTime";
import x from "../../images/x.png";
import cx from "classnames";

interface IProps extends IOrder {
  id: number;
  onDelete: (_id: number) => void;
}

const ActiveOrderTile = ({ id, items, created_at, onDelete }: IProps) => {
  return (
    <div className="w-full flex">
      <div
        className={cx(
          "w-full flex justify-between bg-lightblue rounded-xl items-center p-4"
        )}
      >
        <div key={id} className="w-full space-y-3 text-xl">
          {items.map((item, idx) => (
            <div key={idx} className="flex items-center text-lightyellow">
              <p className="font-semibold">{`${item.count}x ${item.name}`}</p>
              {item.options && (
                <p className="pl-2">{optionsString(item.options)}</p>
              )}
            </div>
          ))}
        </div>
        {created_at && (
          <p className="text-lightyellow">{parseTime(created_at)}</p>
        )}
      </div>
      <button className="bg-lightblue rounded-xl" onClick={() => onDelete(id)}>
        <img className="w-16 p-0.5" src={x} alt="delete" />
      </button>
    </div>
  );
};

export default ActiveOrderTile;
