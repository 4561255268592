import plus from "../../images/plus.png";
import minus from "../../images/minus.png";

interface IProps {
  count: number;
  onCountChange: (count: number) => void;
}

const NumberPicker = ({ count, onCountChange }: IProps) => {
  return (
    <div className="flex items-center gap-x-2 pt-6">
      <button
        onClick={() => onCountChange(count - 1)}
        className="bg-primary p-2 rounded-full"
      >
        <img src={minus} className="w-6 h-6" />
      </button>
      <p className="font-bold text-xl">{count}x</p>
      <button
        onClick={() => onCountChange(count + 1)}
        className="bg-primary p-2 rounded-full"
      >
        <img src={plus} className="w-6 h-6" />
      </button>
    </div>
  );
};

export default NumberPicker;
